const A_CHAR_CODE = 65;
const M_CHAR_CODE = 77;
/**
 * 選擇權商品代碼字串，轉中文
 *
 * @example
 *   //
 *   expect(optionTranslator('TX118000M2')).toBe('台指1月W1選18000Put')
 *   expect(optionTranslator('TXO18000N2')).toBe('台指2月月選18000Put')
 *   expect(optionTranslator('TX217800A2')).toBe('台指1月W2選17800Call')
 *   expect(optionTranslator('TXO18000L2')).toBe('台指12月月選18000Call')
 *   expect(optionTranslator('TXO18000X1')).toBe('台指12月月選18000Put')
 *   expect(optionTranslator('TXO18000X1', true)).toBe('台指12月W3選18000Put')
 */
export const optionTranslator = (symbol, onlyWeekMode) => {
    if (!symbol || symbol.length != 10) {
        return '';
    }
    const weekCode = symbol[2];
    const readableSymbol = getStrikePrice(symbol);
    const isCall = isCallOption(symbol);
    const month = charToMonth(symbol[8] || '-');
    const cpType = isCall ? 'Call' : 'Put';
    const isMonthOption = weekCode === 'O';
    const result = isMonthOption
        ? `台指${month}月${onlyWeekMode ? 'W3' : '月'}選${readableSymbol}${cpType}`
        : `台指${month}月W${weekCode}選${readableSymbol}${cpType}`;
    return result;
};
export const opbsAdvancedTranslator = (symbol, bs) => {
    if (symbol.length === 10)
        return optionTranslator(symbol);
    const weekCode = symbol[2];
    const month = charToMonth(symbol[8] || '-');
    const isMonthOption = weekCode === 'O';
    const preResult = `台指${month}月${isMonthOption ? '月' : `W${weekCode}`}選-`;
    if (symbol.includes(':')) {
        // 跨式 ---> TX218850A2:M2
        if (symbol.length == 13) {
            const price = symbol.substring(3, 8);
            return `${preResult}跨式${price}`;
        }
        // 勒式 ---> TX218750A2:18900M2
        if (symbol.length == 18) {
            const price1 = symbol.substring(3, 8) + getType(symbol[8] || '-');
            const price2 = symbol.substring(11, 16) + getType(symbol[16] || '-');
            const displayBS = bs ?? '';
            return `${preResult}勒式${displayBS}${price1}/${displayBS}${price2}`;
        }
        return '錯誤格式';
    }
    else if (symbol.includes('-')) {
        // 轉換 逆轉 ---> TX218350A2-M2
        if (symbol.length == 13) {
            const price = symbol.substring(3, 8);
            return `${preResult}轉${price}`;
        }
        return '錯誤格式';
    }
    else if (symbol.includes('/')) {
        // 價差 ---> TX219200/18700A2
        if (symbol.length == 16) {
            const price1 = symbol.substring(3, 8);
            const price2 = symbol.substring(9, 14);
            const isCall = isCallOption(symbol);
            const localMonth = charToMonth(symbol[symbol.length - 2] || '-');
            const localPreResult = `台指${localMonth}月${isMonthOption ? '月' : `W${weekCode}`}選-`;
            const typeChinese = getType(symbol[symbol.length - 2] || '-') === 'C' ? '買權' : '賣權';
            const bs1 = isCall ? (bs === 'B' ? 'S' : 'B') : bs;
            const bs2 = isCall ? bs : bs === 'B' ? 'S' : 'B';
            if (!bs) {
                return `${localPreResult}${typeChinese}價差 ${price1}/${price2}`;
            }
            return `${localPreResult}${typeChinese}價差 ${bs1}${price1}/${bs2}${price2}`;
        }
        return '錯誤格式';
    }
    else
        return '錯誤格式';
};
/**
 * @example
 *   ;(char = K), (isCall = true)
 *   return 11
 *
 * @param char
 * @param isCall
 * @returns Month
 */
export const charToMonth = (char) => {
    const isCall = char <= 'L';
    return (isCall ? char.charCodeAt(0) - A_CHAR_CODE : char.charCodeAt(0) - M_CHAR_CODE) + 1;
};
export const isOpbsSymbol = (symbol) => {
    return symbol.length >= 10 && symbol.substring(0, 2) === 'TX';
};
export const isPureOpbsSymbol = (symbol) => {
    return symbol.length === 10 && symbol.substring(0, 2) === 'TX';
};
/** 分辨`買權`或`賣權`，非選擇權商品狀況不考慮 */
export const isCallOption = (opbsSymbol) => {
    return opbsSymbol.charAt(8) <= 'L';
};
/** @deprecated 選擇權取出履約價的部分 */
export const getStrikePrice = (opbsSymbol) => {
    if (!opbsSymbol)
        return '';
    return opbsSymbol.substring(3, 8);
};
/** 選擇權取出履約價以及賣買權的字母 */
export const getStrikePriceAndType = (opbsSymbol) => {
    if (!opbsSymbol)
        return '';
    return opbsSymbol.substring(3, 8) + (isCallOption(opbsSymbol) ? 'C' : 'P');
};
export const getType = (char) => {
    return char <= 'L' ? 'C' : 'P';
};
/** 給入合約月份字串，和履約價，得出 call 和 put 的 symbolString */
export const getOptionsSymbolByStrikePrice = (
/** e.g. 202409W2 */
contractMonth, 
/** e.g. 20300 */
strikePrice) => {
    const year_code = contractMonth.substring(3, 4);
    const month = parseInt(contractMonth.substring(4, 6), 10);
    const week_code = contractMonth.length > 6 ? contractMonth.substring(7, 8) : 'O';
    const call_code = String.fromCharCode(64 + month); // A - K
    const put_code = String.fromCharCode(76 + month); // L - X
    return {
        call: `TX${week_code}${strikePrice}${call_code}${year_code}`,
        put: `TX${week_code}${strikePrice}${put_code}${year_code}`,
    };
};
